import React from 'react'
import { bool, number, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './IconButton.module.scss'

import Icon from '../Icon'
import SmartLink from '../SmartLink'

const sizes = ['s', 'm', 'l']

/**
 * A simple button component used to present clickable Icons
 */

const IconButton = ({
  a11yText,
  children,
  icon,
  iconHeight,
  iconWidth,
  increaseHitArea,
  light,
  muted,
  primary,
  rounded,
  size,
  solid,
  ...other
}) => (
  <SmartLink
    className={classNames(
      styles.IconButton,
      increaseHitArea && styles.increaseHitArea,
      light && styles.light,
      muted && styles.muted,
      rounded && styles.rounded,
      primary && styles.primary,
      solid && styles.solid,
      size && styles[`size-${size}`]
    )}
    {...other}
  >
    <div className={styles.IconButtonInner}>
      <Icon
        type={icon}
        height={iconHeight}
        width={iconWidth}
        a11yText={a11yText}
      />
      {children && <span className={styles.IconButtonText}>{children}</span>}
    </div>
  </SmartLink>
)

IconButton.propTypes = {
  a11yText: string.isRequired,
  children: string,
  icon: string.isRequired,
  iconHeight: number,
  iconWidth: number,
  increaseHitArea: bool,
  light: bool,
  muted: bool,
  primary: bool,
  rounded: bool,
  size: oneOf(sizes),
  solid: bool
}

export default IconButton
