import React from 'react'
import { oneOf } from 'prop-types'

import CheckFieldTemplate from './CheckFieldTemplate'
import CheckFieldAltTemplate from './CheckFieldAltTemplate'
import MultiTextFieldTemplate from './MultiTextFieldTemplate'
import TextFieldTemplate from './TextFieldTemplate'
import TextFieldAltTemplate from './TextFieldAltTemplate'

export const templateTypes = [
  'check',
  'checkAlt',
  'multiText',
  'text',
  'textAlt'
]

/**
 * Pre-configured combinations of Field components which cover most
 * common use cases.
 */

const FieldTemplate = ({ template, ...other }) => {
  const componentMap = {
    check: () => <CheckFieldTemplate {...other} />,
    checkAlt: () => <CheckFieldAltTemplate {...other} />,
    multiText: () => <MultiTextFieldTemplate {...other} />,
    text: () => <TextFieldTemplate {...other} />,
    textAlt: () => <TextFieldAltTemplate {...other} />
  }

  return componentMap[template]()
}

FieldTemplate.defaultProps = {
  template: 'text'
}

FieldTemplate.propTypes = {
  template: oneOf(templateTypes)
}

export default FieldTemplate
