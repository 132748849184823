import React, { useState } from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './CheckFieldAltTemplate.module.scss'

import Field from '../../Field'
import IconButton from '../../IconButton'
import ShrinkWrap from '../../ShrinkWrap'

const CheckFieldAltTemplate = ({
  assistance,
  children,
  controlName,
  feedback,
  inverse,
  status
}) => {
  const [assistanceVisible, setAssistanceVisible] = useState(false)

  const handleAssistanceReveal = () => {
    setAssistanceVisible(true)
  }

  return (
    <Field id={`field--${controlName}`} status={status}>
      <div
        className={classNames(
          styles.CheckFieldAltTemplateHeader,
          assistanceVisible ? styles.assistanceVisible : styles.assistanceHidden
        )}
      >
        <ShrinkWrap fullWidth>
          <ShrinkWrap.Item vAlign="middle">
            <Field.Answer>{children}</Field.Answer>
          </ShrinkWrap.Item>
          {assistance && (
            <ShrinkWrap.Item shrink vAlign="middle">
              <div className={styles.CheckFieldAltTemplateButton}>
                <IconButton
                  icon="info"
                  a11yText="See additional information about this question"
                  onClick={handleAssistanceReveal}
                  size="s"
                  increaseHitArea
                />
              </div>
            </ShrinkWrap.Item>
          )}
        </ShrinkWrap>
        {assistance && (
          <div className={styles.CheckFieldAltTemplateAssistance}>
            <Field.Assistance>{assistance}</Field.Assistance>
          </div>
        )}
      </div>
      {feedback && (
        <Field.Feedback inverse={inverse}>{feedback}</Field.Feedback>
      )}
    </Field>
  )
}

CheckFieldAltTemplate.propTypes = {
  assistance: node,
  controlName: string.isRequired,
  children: node.isRequired,
  feedback: node,
  inverse: bool,
  status: oneOf(['error', 'notice', 'success', 'warning'])
}

export default CheckFieldAltTemplate
