import React, { useState } from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './TextFieldAltTemplate.module.scss'

import Field from '../../Field'
import IconButton from '../../IconButton'

const TextFieldAltTemplate = ({
  alwaysShowAssistance,
  assistance,
  children,
  controlName,
  disabled,
  feedback,
  inverse,
  label,
  required,
  status
}) => {
  const [assistanceVisible, setAssistanceVisible] =
    useState(alwaysShowAssistance)

  const handleAssistanceReveal = () => {
    setAssistanceVisible(true)
  }

  return (
    <Field id={`field--${controlName}`} status={status}>
      <div
        className={classNames(
          styles.TextFieldAltTemplateHeader,
          assistanceVisible ? styles.assistanceVisible : styles.assistanceHidden
        )}
      >
        <div className={styles.TextFieldAltTemplateHeaderInner}>
          <Field.Question
            disabled={disabled}
            htmlFor={controlName} /* matches Control controlName */
          >
            {label}
          </Field.Question>
          {assistance && (
            <div className={styles.TextFieldAltTemplateButton}>
              <IconButton
                icon="info"
                a11yText="See additional information about this question"
                onClick={handleAssistanceReveal}
                size="s"
                increaseHitArea
              />
            </div>
          )}
        </div>
        {assistance && (
          <div className={styles.TextFieldAltTemplateAssistance}>
            <Field.Assistance>{assistance}</Field.Assistance>
          </div>
        )}
      </div>
      <Field.Answer>{children}</Field.Answer>
      {feedback && (
        <Field.Feedback inverse={inverse}>{feedback}</Field.Feedback>
      )}
    </Field>
  )
}

TextFieldAltTemplate.propTypes = {
  alwaysShowAssistance: bool,
  assistance: node,
  controlName: string.isRequired,
  children: node.isRequired,
  disabled: bool,
  feedback: node,
  inverse: bool,
  label: node.isRequired,
  required: bool,
  status: oneOf(['error', 'notice', 'success', 'warning'])
}

export default TextFieldAltTemplate
