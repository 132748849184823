import React from 'react'
import classNames from 'classnames'
import { bool, node, string } from 'prop-types'

import styles from './ShrinkWrapWrapper.module.scss'

const ShrinkWrapWrapper = ({ children, as, fixed, fullWidth, mobileStack }) => {
  const ShrinkWrapWrapperEl = as
  return (
    <ShrinkWrapWrapperEl
      className={classNames(
        styles.ShrinkWrapWrapper,
        fixed && styles.fixed,
        fullWidth && styles.fullWidth,
        mobileStack && styles.mobileStack
      )}
    >
      {children}
    </ShrinkWrapWrapperEl>
  )
}

ShrinkWrapWrapper.defaultProps = {
  as: 'div'
}

ShrinkWrapWrapper.propTypes = {
  children: node.isRequired,
  as: string,
  fixed: bool,
  fullWidth: bool,
  mobileStack: bool
}

export default ShrinkWrapWrapper
